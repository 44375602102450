import classNames from 'classnames';
import { useState } from 'react';
import { QUESTION_KEYS } from '../../constants/names';
import { useTranslation } from '../../context/LanguageProvider';
import { Button } from '../UI';
import s from './PollModal.module.scss';
import { formatTime } from '../../helpers/utils';

const { CHAPTER_NAME, QUESTION } = QUESTION_KEYS;

function formatRankingQuestion(question) {
  const arr = question.split('(');
  arr[1] = arr[1].replace(';', '<br />').replace('(', '').replace(')', '').replace(':', '&nbsp;:');
  return arr;
}

const PollModal = ({
  onClose, data, isRanking, pollData, pollTimer, showPollResultFlag, closePoll, noOfVotes,
}) => {
  const { t } = useTranslation();
  const [ rankDisplayType, setRankDisplayType ] = useState('pollPercent');
  let formattedQuestion = '';
  if (isRanking) formattedQuestion = formatRankingQuestion(data?.data?.question?.[QUESTION]);

  const getRankSuffix = (rank) => {
    switch (rank) {
      case 1:
        return '1st';

      case 2:
        return '2nd';

      case 3:
        return '3rd';

      default:
        return `${rank}th`;
    }
  };

  const getVoteDetails = (el) => {
    const voteDetails = `${el.pollCount} ${t('of')} ${el.pollTotalCount} ${t('voters')}`;
    return voteDetails;
  };

  const onRankDisplayChanged = (e) => {
    setRankDisplayType(e.currentTarget.value);
  };

  return (
    <div className={s.background}>
      <div
        onClick={e => e.stopPropagation()}
        className={classNames(s.modal, { [s.ranking]: isRanking })}>
        <div className={s.heading}>
          <div className={s.heading__present}>
            <div className={s.heading__title}>{t(data.data.question[CHAPTER_NAME])}</div>
            {!isRanking ? (
              <div className={s.heading__subtitle}>{t(data.data.question[QUESTION])}</div>
            ) : (
              <div className={s.heading__subtitle}>
                <div>{formattedQuestion[0]}</div>
              </div>
            )}
          </div>
        </div>
        {!showPollResultFlag && <div className={s.pollTimerBody}>
          <div
            className={classNames(s.pollTimer)}>
            <div>{formatTime(pollTimer) || '0 sec'}</div>
            <div className={s.pollTimerLabel}>
              {t('remaining to vote')}
            </div>
            <div className={s.pollCount}>
              {noOfVotes} {t(noOfVotes > 1 ? 'votes' : 'votes')}
            </div>
          </div>
          <div className={s.showPollBtnHolder}>
            <div
              className={s.showPollBtn}
              onClick={closePoll}>{t('Close Poll and Show Results')}</div>
          </div>
        </div>}
        {showPollResultFlag && pollData.length > 0 && (
          <div className={s.pollBody}>
            {!isRanking && pollData && pollData.map((el, idx) => (
              <div
                key={el.id}
                className={classNames(s.pollInfo,
                  { [s.pollVerdict]: el.pollVerdict, [s.pollNextOrder]: el.pollSecondMax })}>
                <div className={s.pollDetails}>
                  <div className={s.pollVotes}>{getVoteDetails(el) || 0}</div>
                  <div className={s.pollPerct}>
                    ({el.pollPercent || 0}%)
                  </div>
                </div>
                <div className={s.pollItem}>{t(el.pollItem)}</div>
              </div>
            ))}
            {isRanking && pollData && (
              <div className={s.pollRankContainer}>
                <div className={s.pollRankMenu}>
                  <input
                    type='radio'
                    name='Percentages'
                    value='pollPercent'
                    checked={rankDisplayType === 'pollPercent'}
                    onChange={onRankDisplayChanged}
                  />
                  {t('Percentages')}
                  <input
                    type='radio'
                    name='Count'
                    value='pollCount'
                    checked={rankDisplayType === 'pollCount'}
                    onChange={onRankDisplayChanged}
                  />
                  {t('Count')}
                  <input
                    type='radio'
                    name='Both'
                    value='both'
                    checked={rankDisplayType === 'both'}
                    onChange={onRankDisplayChanged}
                  />
                  {t('Both')}
                </div>
                <div className={classNames(s.pollRankBody)}>
                  <div className={s.pollRankHeader}>
                    <div className={s.pollAnswer}>{t('Answer')}</div>
                    {pollData && pollData.map((pc, rowIdx) => (
                      <div key={rowIdx} className={s.pollRank}>
                        {getRankSuffix(rowIdx + 1)} Rank
                      </div>
                    ))}
                  </div>
                  <div className={s.pollRankHolder}>
                    {pollData.map((pc, rowIdx) => (
                      <div key={rowIdx.toString().concat('pollContainer')} className={s.pollAnswerContainer}>
                        <div key={rowIdx.toString().concat('pollItem')} className={s.pollAnswer}>{pc.pollItem}</div>
                        {pc[rankDisplayType].map((pI, colIndx) => (
                          <div
                            key={colIndx.toString().concat(rowIdx)}
                            className={classNames(s.pollRank,
                              {
                                [s.pollVerdict]: pc.pollCount[rowIdx][colIndx] === pc.pollVerdict,
                              },
                              {
                                [s.pollSecondMax]:
                                  pc.pollCount[rowIdx][colIndx] === pc.pollSecondMax,
                              })}>
                            {pI[rowIdx]}
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
        {showPollResultFlag
          && pollData.length === 0
          && <div className={s.pollNoData}>No data found</div>}
        {showPollResultFlag && <div className={s.actions}>
          <Button variant='primary' onClick={onClose}>
            {t('Close')}
          </Button>
        </div>}
      </div>
    </div>
  );
};

export default PollModal;
