import { createAsyncThunk } from '@reduxjs/toolkit';
import { AXIOS } from '../../api/axios';
import {
  CHOICE_TYPES, QUESTION_KEYS, TEMPLATE_TYPES,
} from '../../constants/names';
import { getErrorMessage } from '../../helpers/api';
import { setAlert } from '../slices/alertSlice';
import {
  setSectionFinished, setSelected, setRowSelected, setComment,
  finishCase, setNextLoading, toggleShareable, setCaseStarted,
} from '../slices/currentInterviewSlice';
import { updateInterview, finishEvent, incrementChoiceCount } from '../slices/eventSlice';
import { startSurvey } from './eventThunk';

const {
  SECTION, OPTION, COMMENT, CASE,
} = CHOICE_TYPES;

const { CHAPTER_TYPE } = QUESTION_KEYS;
const { RANKING, MULTIPE_CHOICE, TABLE_VIEW } = TEMPLATE_TYPES;

export const setChoicesThunk = createAsyncThunk(
  'interview/setChoices',
  async ({
    type, operation = 1, value = {}, nextTab,
  }, { getState, dispatch }) => {
    const { currentInterview, event } = getState();
    const {
      id, tId, activeTab, sheetContent,
    } = currentInterview;
    dispatch(incrementChoiceCount());
    const currentSheet = sheetContent.find(sheet => sheet.title === activeTab);
    if (currentInterview?.started === undefined || !currentInterview?.started) {
      dispatch(setCaseStarted());
    }
    value.caseId = tId;
    if ([ SECTION, OPTION, COMMENT ].includes(type)) {
      value.section = activeTab;
      if (currentSheet.data.question[CHAPTER_TYPE] === RANKING) {
        const questionData = currentSheet.data;
        const orders = questionData.orders || questionData.answers.map(answer => answer.id);
        value.orders = orders;
      }
    }
    if ([ SECTION, CASE ].includes(type)) {
      dispatch(setNextLoading(true));
    }
    if (!event.isOnlyCase && ![ SECTION, CASE ].includes(type)) {
      AXIOS.post('/client/choice/set', { type, operation, value }).catch((err) => {
        dispatch(setAlert({ message: getErrorMessage(err) }));
        throw Error(err);
      });
    } else if (!event.isOnlyCase) {
      await AXIOS.post('/client/choice/set', { type, operation, value }).catch((err) => {
        dispatch(setAlert({ message: getErrorMessage(err) }));
        throw Error(err);
      });
    }
    if ([ SECTION, CASE ].includes(type)) {
      dispatch(setNextLoading(false));
    }
    let currentAction;
    let currentPayload;
    if (type === OPTION) {
      const action = [ TABLE_VIEW, MULTIPE_CHOICE ]
        .includes(currentSheet.data.question[CHAPTER_TYPE])
        ? setRowSelected
        : setSelected;
      currentAction = action;
      currentPayload = { ...value, operation };
    } else if (type === SECTION) {
      currentAction = setSectionFinished;
      currentPayload = { ...value, nextTab };
    } else if (type === COMMENT) {
      currentAction = setComment;
      currentPayload = { ...value, operation };
    } else if (type === CASE) {
      currentAction = finishCase;
      currentPayload = null;
      if (!event.isOnlyCase) {
        dispatch(updateInterview({ ...currentInterview, finished: true }));
        const otherCases = event.cases.filter(item => item.id !== id);
        let isAllFinished = true;
        for (let i = 0; i < otherCases.length; i += 1) {
          if (!otherCases[i].finished) {
            isAllFinished = false;
            break;
          }
        }
        if (isAllFinished) {
          dispatch(finishEvent());
          dispatch(startSurvey());
        }
      }
    }
    dispatch(currentAction(currentPayload));
  },
);

export const getIsShareableThunk = createAsyncThunk(
  'userInfo/loginUser',
  async (_, { dispatch, getState }) => {
    const { uuid } = getState().currentInterview;
    const response = await AXIOS.post('/client/status', { uuid, type: 'case' });
    dispatch(toggleShareable(response.data.status));
  },
);
