import React from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import s from './Human.module.scss';
import { HUMAN_PARTS_CONFIGS } from '../../configs/physicalConfigs';
import { HUMAN_SIDES, PHYSICAL_ITEM_IDS } from '../../constants/physicalConstants';
import { getPatientGender } from '../../helpers/utils';
import FemaleFront from '../../assets/nImages/Anatomy/L_Female_Front.png';
import FemaleBack from '../../assets/nImages/Anatomy/L_Female_Back.png';
import MaleFront from '../../assets/nImages/Anatomy/L_Male_Front.png';
import MaleBack from '../../assets/nImages/Anatomy/L_Male_Back.png';

const { EXAM, SIDE } = PHYSICAL_ITEM_IDS;

function getAnatomy(anatomy) {
  switch (anatomy) {
    case 'FemaleFront':
      return FemaleFront;
    case 'FemaleBack':
      return FemaleBack;
    case 'MaleFront':
      return MaleFront;
    case 'MaleBack':
      return MaleBack;
    default: return '';
  }
}

const Human = ({
  zoom, back, data, onHumanPartClick, physicalCategories, order,
}) => {
  const { gender } = useSelector(state => state.currentInterview);
  const sex = getPatientGender(gender);
  const position = back ? 'Back' : 'Front';
  const imgSrc = getAnatomy(sex + position);
  return (
    <div className={s.human}>
      <div className={s.human__body}>
        <div className={classNames(s.human_img_container, s[sex], s[zoom])}>
          {<img className={classNames(s.human__svg, s[sex])} src={imgSrc} alt='Female Front' />}
        </div>
        {data.map((item) => {
          const isBoth = item[physicalCategories[SIDE]] === HUMAN_SIDES.BOTH;
          const currentOrder = !item.selected ? order : -1;
          const isDouble = HUMAN_PARTS_CONFIGS[item[physicalCategories[EXAM]]]?.double;
          if (isDouble && isBoth) {
            return Object.values(HUMAN_PARTS_CONFIGS[item[physicalCategories[EXAM]]].class).map(
              el => (
                <div key={item.id + el} className={classNames(s.human__part, s[el], s[sex])}>
                  <div className={s.human__dot}>
                    <div className='img-container'>
                      <label className={s.human__label}>
                        <input
                          type='checkbox'
                          className={classNames(s.human__checkbox, {
                            [s.pollingItem]: item.Polling,
                          })}
                          checked={!!item.selected}
                          onChange={() => {
                            onHumanPartClick(item.id, item.selected, false, back, currentOrder);
                          }}
                        />
                        <span
                          className={classNames(s.human__checkmark, {
                            [s.pollingItem]: item.Polling,
                          })}
                        />
                      </label>
                    </div>
                  </div>
                </div>
              ),
            );
          }
          const currentClass = isDouble
            ? HUMAN_PARTS_CONFIGS[item[physicalCategories[EXAM]]]?.class[
              item[physicalCategories[SIDE]]
            ]
            : HUMAN_PARTS_CONFIGS[item[physicalCategories[EXAM]]]?.class;
          return (
            <div
              key={item.id}
              className={classNames(s.human__part, s[currentClass], s[sex])}>
              <div className={s.human__dot}>
                <div className='img-container'>
                  <label className={s.human__label}>
                    <input
                      type='checkbox'
                      className={classNames(s.human__checkbox, {
                        [s.pollingItem]: item.Polling,
                      })}
                      checked={!!item.selected}
                      onChange={
                        () => onHumanPartClick(item.id, item.selected, false, back, currentOrder)}
                    />
                    <span
                      className={classNames(s.human__checkmark, {
                        [s.pollingItem]: item.Polling,
                      })}
                    />
                  </label>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Human;
