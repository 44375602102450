import React from 'react';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import s from './PatientCasesCard.module.scss';
import { Button } from '../UI';
import {
  GENDER_NAMES, INTERVIEW_TABS_CHAPTERS, PATIENT_BASE_INFO_KEYS,
} from '../../constants/names';
import { selectCurrentInterview, setInterview } from '../../redux/slices/currentInterviewSlice';
import { updateInterview } from '../../redux/slices/eventSlice';
import { getPatientDefaultImage } from '../../helpers/utils';
import { useTranslation } from '../../context/LanguageProvider';

const {
  GENDER,
  AGE,
  FIRST_NAME,
  HISTORY,
  LAST_NAME,
  SYMPTOMS,
  VISIT,
  VISIT_KEY,
  SYMPTOMS_LABEL,
  HISTORY_LABEL,
} = PATIENT_BASE_INFO_KEYS;

const PatientCasesCard = ({ data, selected }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const activeInterview = useSelector(selectCurrentInterview);

  const isMobile = useMediaQuery({
    query: '(max-width: 600px)',
  });

  const { baseInfo } = data;

  const onStartClick = () => {
    if (activeInterview.id) {
      dispatch(updateInterview(activeInterview));
    }
    if (activeInterview.id !== data.id) {
      let activeTab = INTERVIEW_TABS_CHAPTERS.INTRODUCTION;
      const currentEvent = data;
      if (currentEvent.started && !currentEvent.finished) {
        const tabs = currentEvent.sheetContent;
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < tabs.length; i++) {
          if (!tabs[i].finished) {
            activeTab = tabs[i].title;
            break;
          }
        }
      }
      dispatch(setInterview({ activeTab, ...currentEvent }));
    }
    navigate(`/interviews/${data.id}`);
  };

  return (
    <div className={classNames(s.patientCases__content, selected && s.selected)}>
      <div className={s.patientCases__information}>
        <div className={s.patientCases__details}>
          <div className={s.patientCases__picture} >
            <img
              className={s.patientCases__img}
              src={
                data.avatar
                  ? process.env.REACT_APP_IMAGE_URL + data.avatar
                  : getPatientDefaultImage(baseInfo[GENDER])
                }
              alt='Patient' />
          </div>
          <div className={s.patientCases__info}>
            <div className={s.patientCases__person}>
              <div className={s.patientCases__name}>
                { t(baseInfo[GENDER]) } {baseInfo[FIRST_NAME] || ''} {baseInfo[LAST_NAME] || ''}
              </div>
              <div className={s.patientCases__gender}>
                { t(GENDER_NAMES[baseInfo[GENDER]]) }{baseInfo[AGE] && `, ${baseInfo[AGE]}`}
              </div>
              <div className={s.patientCases__description}>
                {baseInfo[VISIT] && `${t(baseInfo[VISIT])} ${t(baseInfo[VISIT_KEY])}`}
              </div>
            </div>
          </div>
          <div className={s.patientCases__badge}>{t('Patient')}</div>
        </div>
        <section className={s.patientCases__AddDetails}>
          {baseInfo[SYMPTOMS] && Array.isArray(baseInfo[SYMPTOMS]) && !!baseInfo[SYMPTOMS].length
          && <div className={s.patientCases__history}>
            <div className={s.patientCases__historyTitle}>{t(baseInfo[SYMPTOMS_LABEL])}</div>
            <div className={s.patientCases__historyText}>
              {baseInfo[SYMPTOMS].map((paragraph, i) => (
                <p key={i} className={s.patientCases__historyText_paragraph}>
                  {paragraph}
                </p>
              ))}
            </div>
          </div>
          }
          {baseInfo[HISTORY] && Array.isArray(baseInfo[HISTORY]) && !!baseInfo[HISTORY].length
          && <div className={s.patientCases__history}>
            <div className={s.patientCases__historyTitle}>{t(baseInfo[HISTORY_LABEL])}</div>
            <div className={s.patientCases__historyText}>
              {baseInfo[HISTORY].map((paragraph, i) => (
                <p key={i} className={s.patientCases__historyText_paragraph}>
                  {paragraph}
                </p>
              ))}
            </div>
          </div>
        }
        </section>
      </div>
      {isMobile && !selected && (
      <div className={classNames(s.patientCases__start, s.mobileStartButton)}>
        <Button disabled={data.finished} onClick={onStartClick}>{data.finished ? 'Finished' : 'Start'}</Button>
      </div>
      )}
    </div>
  );
};

export default PatientCasesCard;
