import { Button } from '../UI';
import s from './ConfirmModal.module.scss';
import { useTranslation } from '../../context/LanguageProvider';

const ConfirmModal = ({ onConfirm, onClose, loading }) => {
  const { t } = useTranslation();
  const confirmHandler = () => {
    onConfirm();
  };

  return (
    <div onClick={onClose} className={s.background}>
      <div onClick={e => e.stopPropagation()} className={s.modal}>
        <div className={s.question}><div>{t('Are you sure?')}</div></div>
        <div className={s.actions}>
          <Button variant='transparent' onClick={onClose}>{t('Cancel')}</Button>
          <Button loading={loading} onClick={confirmHandler}>{t('Confirm')}</Button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmModal;
