import React from 'react';
import s from './DoctorCard.module.scss';
import { useTranslation } from '../../context/LanguageProvider';
import { DOCTOR_BASE_INFO_KEYS } from '../../constants/names';
import doctorDefaultImage from '../../assets/nImages/default_doctor.png';

const { NAME, TITLE, LOCATION } = DOCTOR_BASE_INFO_KEYS;

function getDoctorImage(baseInfo, files) {
  let defaultImage = doctorDefaultImage;
  let doctorImage = null;
  if (baseInfo.doctorImage) {
    doctorImage = files.filter(
      e => e.name.split('.')[0] === baseInfo.doctorImage.split('.')[0],
    )[0];
    if (doctorImage) {
      defaultImage = process.env.REACT_APP_IMAGE_URL + doctorImage.mozJpeg;
    }
  }
  return defaultImage;
}

const DoctorCard = ({ data }) => {
  const { t } = useTranslation();
  const { baseInfo, files } = data;
  const doctorImage = getDoctorImage(baseInfo, files);
  return (
    <section className={s.doctorContainer}>
      <div className={s.doctorPicture}>
        <img
          className={s.doctorImage}
          src={doctorImage}
          alt='Doctor'
        />
      </div>
      <div className={s.doctorInfo}>
        <div className={s.doctorName}>
          {t(baseInfo[TITLE]) || ''}&nbsp;
          {t(baseInfo[NAME]) || ''}
        </div>
        <div className={s.doctorLocation}>{t(baseInfo[LOCATION]) || ''}</div>
      </div>
      <div className={s.doctorBadge}>{t('Doctor')}</div>
    </section>
  );
};

export default DoctorCard;
