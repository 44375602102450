import classNames from 'classnames';
import { React } from 'react';
import { useSelector } from 'react-redux';
import s from './InterviewChatBox.module.scss';
import doctorDefaultImage from '../../../../../../assets/nImages/default_doctor.png';
import { getPatientDefaultImage } from '../../../../../../helpers/utils';

function getDoctorImage(baseInfo, files) {
  let defaultImage = doctorDefaultImage;
  let doctorImage = null;
  if (baseInfo.doctorImage) {
    doctorImage = files.filter(
      e => e.name.split('.')[0] === baseInfo.doctorImage.split('.')[0],
    )[0];
    if (doctorImage) {
      defaultImage = process.env.REACT_APP_IMAGE_URL + doctorImage.mozJpeg;
    }
  }
  return defaultImage;
}

const InterviewChatBox = ({ item }) => {
  const {
    avatar, gender, baseInfo, files,
  } = useSelector(state => state.currentInterview);
  const doctorImage = getDoctorImage(baseInfo, files);
  return (
    <div className={s.chatWrapper}>
      <div className={classNames(s.chatBox, s.chatQuestion)}>
        <div className={s.chatQuestionItem}>{item?.Question}</div>
        <img src={doctorImage} alt="Doctor" className={s.chatDoctor_img} />
      </div>
      <div className={classNames(s.chatBox, s.chatAnswer)}>
        <img src={avatar ? process.env.REACT_APP_IMAGE_URL + avatar : getPatientDefaultImage(gender)} alt="Doctor" className={s.chatDoctor_img} />
        <div className={s.chatAnswerItem}>{item?.Answer}</div>
      </div>
    </div>
  );
};

export default InterviewChatBox;
