import classNames from 'classnames';
import React from 'react';
import s from './InterviewItem.module.scss';

const InterviewItem = ({ data, onItemClick, keys }) => (
  <li
    className={s.anamnesisDropdown__item}
    onClick={onItemClick}>
    {
      <div className={classNames(s.anamnesisDropdown__question, data.selected ? s.anamesisSelected_Question : '')}>
        {data[keys[1]]?.split('\n').map((paragraph, i) => (
          <p key={i} className={classNames('paragraph', data.Polling && !data.selected ? s.anamesisPoll_Enabled : '')}>{paragraph}</p>
        ))}
      </div>
    }
  </li>
);

export default InterviewItem;
