import React, { useState } from 'react';
import dayjs from 'dayjs';
import QRCode from 'qrcode.react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import s from './Welcome.module.scss';
import medqpLogo from '../../assets/nImages/MEDQP_LOGO.svg';
import bubbleImage from '../../assets/nImages/Green_Bubble.svg';
import { Button } from '../../components/UI';
import { selectEvent } from '../../redux/slices/eventSlice';
import { EVENT_TYPE_NAMES, INTERVIEW_TABS_CHAPTERS } from '../../constants/names';
import { setInterview, setPollingData } from '../../redux/slices/currentInterviewSlice';
import { beginPolling, resetCaseThunk } from '../../redux/thunks/eventThunk';
import ConfirmModal from '../../components/ConfirmModal/ConfirmModal';

const Welcome = ({ notFound }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const event = useSelector(selectEvent);
  const baseInfo = event.cases ? event.cases[0].baseInfo : {};
  const shortURL = event.shortURL;
  const { id, shareable } = useSelector(state => state.currentInterview);
  const { isOnlyCase } = event;
  const [ isConfirmActive, setIsConfirmActive ] = useState(false);
  const translation = event.cases ? event.cases[0].translation : {};

  const onBeginClick = () => {
    if (event.cases.length > 1) {
      return navigate('/interviews');
    }
    let activeTab = INTERVIEW_TABS_CHAPTERS.INTRODUCTION;
    const currentEvent = event.cases[0];
    if (currentEvent.started && !currentEvent.finished) {
      const tabs = currentEvent.sheetContent;
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < tabs.length; i++) {
        if (!tabs[i].finished) {
          activeTab = tabs[i].title;
          break;
        }
      }
    }
    dispatch(setPollingData({ activeTab, ...currentEvent }));
    dispatch(setInterview({ activeTab, ...currentEvent }));
    dispatch(beginPolling(currentEvent));
    return navigate(`/interviews/${currentEvent.id}`);
  };

  const openConfirm = () => {
    setIsConfirmActive(true);
  };

  const startOver = () => {
    dispatch(resetCaseThunk({ caseId: id })).unwrap().then(() => setIsConfirmActive(false));
  };

  return (
    <section className={s.welcome}>
      <section className={s.welcome_container}>
        <div className={s.welcome__logo}>
          <img className={s.welcome__img} src={medqpLogo} alt='MedQP Logo' />
          {!isOnlyCase && event && event?.eventLogo && (
            <div className={s.welcome_bi_logos}>
              <img
                className={s.welcome__img}
                src={`${process.env.REACT_APP_IMAGE_URL}${event?.eventLogo}`}
                alt='BoehringerIngelheim'
              />
            </div>
          )}
        </div>
        <div className={s.welcome__inner}>
          <div className={s.welcome__title}>
            <div className={s.welcome__subtitle}>
              {translation['Welcome to our Interactive']
              || 'Welcome to our Interactive'}
            </div>
            <div className={s.welcome__subtitle}>
              {translation['Digital Patient Case Presentation!']
              || 'Digital Patient Case Presentation!'}
            </div>
            <img
              className={s.wc_title_bubble}
              src={bubbleImage}
              alt='Boehringer Ingelheim'
            />
          </div>
          <section className={s.welcome_inner_container}>
            <div className={s.welcome__inner_bottom}>
              {notFound && (
                <div className={s.grayField}>
                  <div className={s.notFound}>
                    {translation['EVENT NOT FOUND'] || 'EVENT NOT FOUND'}
                  </div>
                </div>
              )}
              {!notFound && isOnlyCase && id && !shareable && (
                <div className={s.grayField}>
                  <div className={s.notFound}>
                    {translation['EVENT IS NOT AVAILABLE']
                      || 'EVENT IS NOT AVAILABLE'}
                  </div>
                </div>
              )}
              {!notFound && !isOnlyCase && event.name && (
                <section className={s.event_details_holder}>
                  <section className={s.qr_code_holder}>
                    <div className={s.qr_code_header}>{translation['Follow this link to join the event'] || 'Follow this link to join the event'}</div>
                    <QRCode
                      id='qr-code'
                      value={shortURL?.diURL}
                      size={100}
                      bgColor='transparent'
                      fgColor='#31363D'
                      height={65}
                      width={65}
                    />
                    <div className={s.tiny_url}>{shortURL?.diURL}</div>
                  </section>
                  {event?.eventImage && <div className={s.welcome_custom_inner}>
                    <img
                      className={s.welcome_custom_img}
                      src={`${process.env.REACT_APP_IMAGE_URL}${event?.eventImage}`}
                      alt='BoehringerCustomEvent'
                    />
                  </div>}
                  {!event?.eventImage && <section className={s.event_info_holder}>
                    <div className={s.welcome__event_info}>
                      {event.name} - {event.therapeuticArea} (
                      {translation[EVENT_TYPE_NAMES[event.eventType]]
                        || EVENT_TYPE_NAMES[event.eventType]}
                      )
                    </div>
                    <div className={s.welcome_case_info}>
                      {translation['Digital Patient Case']
                        || 'Digital Patient Case'}{' '}
                      - {translation[baseInfo.gender]} {baseInfo.firstName}
                    </div>
                    <div className={s.welcome__address}>
                      <div className={s.welcome__location}>
                        {event.city && `${event.city}, `}
                        {event.country}
                      </div>
                      <div className={s.welcome__region}>
                        {event.region} {translation['Region'] || 'Region'}
                      </div>
                    </div>
                    <div className={s.welcome__date}>
                      {dayjs(event.startDate).format('MMMM D, YYYY')}
                    </div>
                  </section>}
                </section>
              )}
            </div>
          </section>
        </div>
        <div className={s.welcome_footer}>
          {event.finished ? (
            <div className={s.grayField}>
              <div className={s.notFound}>
                {translation['EVENT HAS FINISHED'] || 'EVENT HAS FINISHED'}
              </div>
            </div>
          ) : !notFound && !isOnlyCase && event.name && (
            <Button onClick={onBeginClick} className={s.welcome__button}>
              {translation['Begin'] || 'Begin'}
            </Button>
          )}
        </div>
      </section>
      {!notFound && <section className={s.welcomeResetHolder}>
        <div className={s.welcomeResetButton} onClick={openConfirm}>
          {translation['Reset'] || 'Reset'}
        </div>
      </section>}
      {/* MODAL */}
      {isConfirmActive && (
        <ConfirmModal
          onConfirm={startOver}
          onClose={() => setIsConfirmActive(false)}
          loading={event.isStartOverLoading}
        />
      )}
    </section>
  );
};

export default Welcome;
