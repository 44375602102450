import React from 'react';
import classNames from 'classnames';
import s from './DropdownRow.module.scss';

const DropdownRow = ({
  toggleItem, isActive, isPolling, value, isWriteAllow, columnWidth,
}) => (
  <li
    style={{ width: `${columnWidth}` }}
    onClick={toggleItem}
    className={classNames(s.dropdownRow__item,
      { [s.pollingItem]: isPolling, [s.active]: isActive, [s.treatment]: isWriteAllow })}>
    <div className={s.dropdownRow__checkbox} />
    <div className={s.dropdownRow__text}>
      {value?.split('\n').map((paragraph, i) => (
        <p key={i} className='paragraph'>
          {paragraph}
        </p>
      ))}
    </div>
  </li>
);

export default DropdownRow;
