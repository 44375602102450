import classNames from 'classnames';
import { useEffect, useRef } from 'react';
import s from '../../ImageInteractive.module.scss';
import { useTranslation } from '../../../../../../context/LanguageProvider';

const PhysicalNavbar = ({ navbarNames, activeNav, setActiveNav }) => {
  const menu = useRef();
  const activeTabRef = useRef(null);
  const { t } = useTranslation();

  useEffect(() => {
    activeTabRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'center' });
  }, [ activeNav ]);

  const capitalize = (text) => {
    const firstChar = text.charAt(0).toUpperCase();
    const remainingText = text.slice(1).toLowerCase();
    return firstChar + remainingText;
  };

  return (
    <nav className={s.physical__menu}>
      {/* <div className={s.physical__scroll}>
        <button
          onClick={() => {
            menu.current.scrollTo({
              left: menu.current.scrollLeft - 300,
              behavior: 'smooth',
            });
          }}
          className={s.physical__scrollButton}>
          <svg className={s.physical__scrollSvg}>
            <use xlinkHref="#arrowLeft" />
          </svg>
        </button>
      </div> */}
      <ul ref={menu} className={s.physical__menuList}>
        {
        navbarNames.map(item => (
          <li
            ref={item === activeNav ? activeTabRef : null}
            key={item}
            className={s.physical__menuItem}>
            <button
              onClick={() => setActiveNav(item)}
              className={classNames(s.physical__menuButton,
                { [s.active]: activeNav === item })}>{t(capitalize(item))}</button>
          </li>
        ))
      }

      </ul>
      {/* <div className={s.physical__scroll}>
        <button
          onClick={() => {
            menu.current.scrollTo({
              left: menu.current.scrollLeft + 300,
              behavior: 'smooth',
            });
          }}
          className={s.physical__scrollButton}>
          <svg className={s.physical__scrollSvg}>
            <use xlinkHref="#arrowRight" />
          </svg>
        </button>
      </div> */}
    </nav>
  );
};

export default PhysicalNavbar;
