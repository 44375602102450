import React from 'react';
import classNames from 'classnames';
import { useMediaQuery } from 'react-responsive';
import s from './Table.module.scss';

const Table = ({ children, className, ...props }) => (
  <div {...props} className={classNames(s.table, { [className]: className })} >
    {children}
  </div>
);

Table.Header = ({ children }) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const isTablet = useMediaQuery({
    query: '(max-width: 900px)',
  });
  if (isTablet) {
    return null;
  }
  return (
    <div className={s.table__header}>{children}</div>
  );
};

Table.HeaderCell = ({ children, length }) => (
  <div className={s.table__headerCell} style={{ width: `calc(100% / ${length})` }}>{children}</div>
);

Table.Body = ({ children, className }) => (
  <div className={classNames(s.table_body, { [className]: className })}>{children}</div>
);

Table.Row = ({ children, className }) => (
  <div className={classNames(s.table__row, { [className]: className })}>{children}</div>
);

Table.Cell = ({
  children, length, title, className,
}) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const isTablet = useMediaQuery({
    query: '(max-width: 900px)',
  });
  const style = !isTablet ? { width: `calc(100% / ${length})` } : {};
  return (
    <div className={classNames(s.table__cell, { [className]: className })} style={style}>
      {isTablet && (
      <div className={s.table__title}>
        {title}
      </div>
      )}
      <div className={s.table__info}>
        {children}</div>
    </div>
  );
};

export default Table;
