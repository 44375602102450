import React from 'react';
import { useSelector } from 'react-redux';
import s from './PatientCases.module.scss';
import PatientCasesCard from '../../components/PatientCasesCard/PatientCasesCard';
import { useTranslation } from '../../context/LanguageProvider';

const PatientCases = () => {
  const cases = useSelector(state => state.event.cases);
  const { t } = useTranslation();

  return (
    <section className={s.patientCases} >
      <div className={s.patientCases__title}>{t('Available Patient Cases')}</div>
      <ul className={s.patientCasesCard__list}>
        {cases?.map(item => (
          <li
            key={item.id}
            className={s.patientCasesCard__item}><PatientCasesCard data={item} /></li>
        ))}
      </ul>

    </section>
  );
};

export default PatientCases;
