import React from 'react';
import { useSelector } from 'react-redux';
import s from './Introduction.module.scss';
import PatientCasesCard from '../../components/PatientCasesCard/PatientCasesCard';
import { selectCurrentInterview } from '../../redux/slices/currentInterviewSlice';
import DoctorCard from '../../components/DoctorCard/DoctorCard';
import { useTranslation } from '../../context/LanguageProvider';

const Introduction = () => {
  const cardData = useSelector(selectCurrentInterview);
  const {
    clientsConnected,
  } = useSelector(state => state.currentInterview);
  const { t } = useTranslation();

  return (
    <section className={s.introContainer}>
      <div className={s.introLeftContainer}>
        <PatientCasesCard data={cardData} selected />
        <DoctorCard data={cardData} />
      </div>
      {(<div className={s.introRightContainer}>
        <div className={s.participantInfo}>
          <div className={s.pIHeader}>{t('Number of Participants')}</div>
          <div className={s.noOfParticipants}>{clientsConnected || 0}</div>
          <div className={s.pIFooter}>{t('Ready to participate in polling')}</div>
        </div>
      </div>)}
    </section>
  );
};

export default Introduction;
