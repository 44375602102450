import classNames from 'classnames';
import React from 'react';
import { QUESTION_KEYS } from '../../constants/names';
import { useTranslation } from '../../context/LanguageProvider';
import s from './Heading.module.scss';

const { CHAPTER_NAME, QUESTION } = QUESTION_KEYS;

function formatRankingQuestion(question) {
  const arr = question.split('(');
  arr[1] = arr[1].replace(';', '<br />').replace('(', '').replace(')', '').replace(':', '&nbsp;:');
  return arr;
}

const Heading = ({ data, headingImage, isRanking }) => {
  const { t } = useTranslation();
  let formattedQuestion = '';
  if (isRanking) formattedQuestion = formatRankingQuestion(t(data.data.question[QUESTION]));
  return (
    <div className={classNames(s.heading, { [s.ranking]: isRanking })}>
      <div className={s.heading__present}>
        <div className={s.heading__title}>{t(data.data.question[CHAPTER_NAME])}</div>
        {!isRanking ? (
          <div className={s.heading__subtitle}>{t(data.data.question[QUESTION])}</div>
        ) : (
          <div className={s.heading__subtitle}>
            <div>{formattedQuestion[0]}</div>
            <div
              className={s.heading__ranking_helpers}
              dangerouslySetInnerHTML={{ __html: formattedQuestion[1] }} />
          </div>
        )}
      </div>
      <div className={classNames(s.heading__picture, { [s.ranking]: isRanking })}>
        {headingImage && <img className={s.heading__img} src={headingImage} alt='' />}
      </div>
    </div>
  );
};

export default Heading;
