import React, {
  useEffect, useMemo, useState, useRef,
} from 'react';
import classNames from 'classnames';
import QRCode from 'qrcode.react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import s from './Menu.module.scss';
import { selectActiveTab, selectInterviewId, setActiveTab } from '../../redux/slices/currentInterviewSlice';
import { getisInterviewPage } from '../../helpers/utils';
import { INTERVIEW_TABS_KEYS, PATIENT_BASE_INFO_KEYS, QUESTION_KEYS } from '../../constants/names';
import { INTRODUCTION_SHEET } from '../../configs/interviewConfigs';
import { useTranslation } from '../../context/LanguageProvider';
import medQpLogo from '../../assets/nImages/MEDQP_LOGO.svg';
import greenBubble from '../../assets/nImages/Green_Bubble.svg';

const { INTRODUCTION } = INTERVIEW_TABS_KEYS;
const { ICON, MENU_IMAGE, CHAPTER_NAME } = QUESTION_KEYS;
const { DISPLAY_NAME } = PATIENT_BASE_INFO_KEYS;

const Menu = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const interviewId = useSelector(selectInterviewId);
  const activeTab = useSelector(selectActiveTab);
  const isOnlyCase = useSelector(state => state.event.isOnlyCase);
  const shortURL = useSelector(state => state.event.shortURL);
  const { pollingIsOpen } = useSelector(state => state.currentInterview);
  const {
    sheetContent,
    id: caseId,
    uuid,
    baseInfo,
  } = useSelector(state => state.currentInterview);
  const activeNavRef = useRef(null);

  const allTabs = useMemo(() => {
    if (!sheetContent) return [ INTRODUCTION_SHEET ];
    if (baseInfo && baseInfo?.[DISPLAY_NAME]) {
      INTRODUCTION_SHEET.data.question[CHAPTER_NAME] = baseInfo[DISPLAY_NAME];
    }
    return [
      INTRODUCTION_SHEET,
      ...sheetContent,
    ];
  }, [ sheetContent ]);

  const getTabIcon = (tab, key = ICON) => {
    if (!tab) return '';
    const icon = tab.data.question[key];
    return [ INTRODUCTION ].includes(tab.title)
      ? icon
      : `${process.env.REACT_APP_IMAGE_URL}images/${icon}`;
  };

  const activeTabData = allTabs.find(item => item.title === activeTab);
  const activeTabImage = getTabIcon(activeTabData, MENU_IMAGE);

  const finishedTabs = useMemo(() => (
    sheetContent?.filter(sheet => sheet.finished).map(item => item.title) || []
  ), [ sheetContent ]);

  const [ isIntroductionFinished, setIsIntroductionFinished ] = useState(
    activeTab !== INTRODUCTION || finishedTabs.length,
  );

  const getIsFinished = (tab) => {
    if ((tab === INTRODUCTION && isIntroductionFinished)) {
      return true;
    }
    return finishedTabs.includes(tab);
  };

  const isInterviewPage = getisInterviewPage(pathname);

  const onNavClick = (nav, isVisited) => {
    if ((nav === INTRODUCTION && isIntroductionFinished && !pollingIsOpen)
      || ((isOnlyCase || localStorage.getItem(`${uuid}_${caseId}_terms`) !== null)
        && (finishedTabs.includes(nav) || isVisited))) {
      dispatch(setActiveTab(nav));
    }
  };

  useEffect(() => {
    if (!isIntroductionFinished && (activeTab !== INTRODUCTION || finishedTabs.length)) {
      setIsIntroductionFinished(true);
    }
  }, [ activeTab, finishedTabs ]);

  useEffect(() => {
    activeNavRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'center' });
  }, [ activeTab ]);

  return (
    <aside className={s.menu}>
      <div className={s.headerLogo}>
        <img src={medQpLogo} alt='MedQP' className={s.headerLogo_png} />
      </div>
      {interviewId && isInterviewPage && (
        <>
          <section className={s.nav_container}>
            <nav className={s.nav}>
              <section className={classNames(s.nav_list_holder, { [s.noQrCode]: isOnlyCase })}>
                <ul className={s.nav__list}>
                  {allTabs.map((navItem) => {
                    const icon = getTabIcon(navItem);
                    return (
                      <li
                        key={navItem.title}
                        ref={navItem.title === activeTab ? activeNavRef : null}
                        className={classNames(s.nav__item, {
                          [s.active]: navItem.title === activeTab,
                          [s.finished]: getIsFinished(navItem.title) && navItem?.visited,
                          [s.clickable]: navItem?.visited && !getIsFinished(navItem.title),
                        })}
                        onClick={() => onNavClick(navItem.title, navItem.visited)}>
                        <div className={s.nav__prop} title={t(navItem.data.question[CHAPTER_NAME])}>
                          <div
                            className={classNames(
                              s.nav__icon,
                              getIsFinished(navItem.title) ? s.navPassed : '',
                            )}>
                            <div
                              className={classNames(s.nav__svg, {
                                [s.activeIcon]: activeTab === navItem.title,
                              })}>
                              <img src={icon} alt='' />
                            </div>
                          </div>
                          <div className={s.nav__text}>
                            {t(navItem.data.question[CHAPTER_NAME])}
                          </div>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </section>
              {!isOnlyCase && (
                <section className={s.qr_code_holder}>
                  <div className={s.qr_code_header}>{t('Follow this link to join the event')}</div>
                  <QRCode
                    id='qr-code'
                    value={shortURL?.diURL}
                    size={80}
                    bgColor='transparent'
                    fgColor='#31363D'
                    height={60}
                    width={60}
                  />
                  <div className={s.tiny_url}>{shortURL?.diURL}</div>
                </section>
              )}
              <div className={s.menu_green_bubble}>
                <img className={s.green_bubble_image} alt='' src={greenBubble} />
              </div>
            </nav>
            {activeTabImage && activeTab !== INTRODUCTION && (
              <div className={classNames(s.menu__pic)}>
                <img className={s.menu__img} src={activeTabImage} alt={''} />
              </div>
            )}
          </section>
        </>
      )}
    </aside>
  );
};

export default Menu;
