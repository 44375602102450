import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import s from './MCCategory.module.scss';
import { setChoicesThunk } from '../../../../../../redux/thunks/interviewThunk';
import { CHOICE_TYPES } from '../../../../../../constants/names';
import DropdownRow from '../../../../../../components/UI/DropdownRow/DropdownRow';

const MCCategory = ({
  title,
  isActive,
  toggleCategory,
  items,
  checkboxKey,
  columnWidth,
  isWriteAllow,
  mcInfo,
}) => {
  const ref = useRef(null);
  const isMobile = useMediaQuery({
    query: '(max-width: 600px)',
  });

  const isBigScreen = useMediaQuery({
    query: '(min-width: 1600px)',
  });

  let maxWidth = '95%';
  const itemCountPerColumn = isBigScreen ? 8 : 5;

  const executeScroll = () => {
    toggleCategory();
    setTimeout(() => {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
    }, 10);
  };
  const [ columnCount, setColumnCount ] = useState(0);
  const dispatch = useDispatch();
  const contentRef = useRef(null);
  useEffect(() => {
    const contentElement = contentRef.current;
    if (contentElement && columnWidth) {
      const itemsCount = Math.ceil(items.length / itemCountPerColumn);
      const colCount = Math.ceil(contentElement.clientWidth / Number(columnWidth));
      if (!isMobile) {
        setColumnCount((itemsCount < colCount ? itemsCount : colCount) || 1);
        if (columnCount > 1) {
          maxWidth = `${
            Number(columnWidth) * columnCount > contentElement.clientWidth
              ? contentElement.clientWidth
              : Number(columnWidth) * columnCount
          }px`;
        }
      } else {
        setColumnCount(1);
        maxWidth = contentElement.clientWidth;
      }
    }
  }, [ items ]);

  const onItemClick = (id, isSelected, isNone) => {
    const operation = isSelected ? 0 : 1;
    const value = {
      optionId: id,
      isNone,
      subSection: title,
    };
    if (!isNone && mcInfo?.isNoneSelected) {
      dispatch(
        setChoicesThunk({
          type: CHOICE_TYPES.OPTION,
          operation: 0,
          value: { optionId: mcInfo?.noneId, subSection: title },
        }),
      ).then(() => {
        dispatch(setChoicesThunk({ type: CHOICE_TYPES.OPTION, operation, value }));
      });
    } else if (mcInfo?.noneIds.length < 2
      && isNone && id === mcInfo?.noneId && !isSelected) {
      dispatch(
        setChoicesThunk({
          type: CHOICE_TYPES.OPTION,
          operation: 0,
          value: { optionId: 'all', isNone: false },
        }),
      ).then(() => {
        dispatch(setChoicesThunk({ type: CHOICE_TYPES.OPTION, operation, value }));
      });
    } else if (mcInfo?.noneIds.length > 1 && mcInfo?.noneIds.includes(id) && !isSelected) {
      dispatch(
        setChoicesThunk({
          type: CHOICE_TYPES.OPTION,
          operation: 0,
          value: { optionId: 'all', isNone: false },
        }),
      ).then(() => {
        dispatch(setChoicesThunk({ type: CHOICE_TYPES.OPTION, operation, value }));
      });
    } else {
      dispatch(setChoicesThunk({ type: CHOICE_TYPES.OPTION, operation, value }));
    }
  };

  return (
    <section ref={contentRef}>
      <button
        ref={ref}
        className={classNames(s.category__button, isActive && s.active)}
        onClick={executeScroll}>
        <div className={s.category__arrow}>
          <svg className={s.category__svg}>
            <use xlinkHref='#arrow' />
          </svg>
        </div>
        <div className={s.category__title}>{title}</div>
      </button>
      <li className={s.category__item}>
        {isActive && (
          <ul
            style={{
              columnCount,
              columnWidth: !isMobile ? `${columnWidth}px` : 'auto',
              width: columnCount === 1 ? '95%' : maxWidth,
            }}
            className={classNames(s.dropdownRow__list)}>
            {items.map((item) => {
              const isNone = mcInfo?.noneIds?.includes(item.id);
              let validValue = null;
              validValue = isNone ? item?.[checkboxKey]?.split('***')[1] : item?.[checkboxKey];
              return (<DropdownRow
                key={item.id}
                className={classNames({ [s.treatment]: isWriteAllow })}
                value={validValue}
                isActive={item.selected}
                isWriteAllow={isWriteAllow}
                toggleItem={() => onItemClick(item.id, item.selected, isNone)}
                isPolling={item.Polling}
                columnWidth={isMobile ? '95%' : `${columnWidth}px`}
              />);
            })}
          </ul>
        )}
      </li>
    </section>
  );
};

export default MCCategory;
