import React from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { Draggable } from 'react-beautiful-dnd';
import s from './DragRow.module.scss';
import { dragQuestion } from '../../../../../../redux/slices/currentInterviewSlice';

const DragRow = ({
  number, children, item, index, noOfQuestions,
}) => {
  const dispatch = useDispatch();
  const moveItemUp = () => {
    if (index - 1 >= 0) {
      dispatch(dragQuestion({ sourceI: index, destI: index - 1 }));
    }
  };
  const moveItemDown = () => {
    if (index + 1 < noOfQuestions) {
      dispatch(dragQuestion({ sourceI: index, destI: index + 1 }));
    }
  };
  return (<Draggable
    key={item.id}
    draggableId={`draggable-${item.id}`}
    index={index}>
    {dragProvided => (
      <div
        ref={dragProvided.innerRef}
        {...dragProvided.draggableProps}
        {...dragProvided.dragHandleProps}
        className={classNames(s.dragRow, s.drag, { [s.pollingItem]: item.Polling })}>
        {number && (
          <div className={s.cardRow__number}>{number}</div>
        )}
        <div className={s.cardRow__info}>
          <div className={s.cardRow__text}>{children}</div>
        </div>
        <div className={s.cardRow__drag}>
          <svg
            className={classNames(s.cardRow__drag__icon, { [s.card_disabled]: index === 0 })}
            onClick={moveItemUp}>
            <rect width="90%" height="100%" transform="translate(2)" fill="#4FB1C2" />
            <use xlinkHref="#upArrow" />
          </svg>
          <svg
            className={classNames(s.cardRow__drag__icon,
              { [s.card_disabled]: index === noOfQuestions - 1 })}
            onClick={moveItemDown}>
            <rect width="90%" height="100%" transform="translate(2)" fill="#4FB1C2" />
            <use xlinkHref="#downArrow" />
          </svg>
          <svg className={classNames(s.cardRow__drag__handle)}>
            <use xlinkHref="#drag" />
          </svg>
        </div>
      </div>
    )}
  </Draggable>);
};
export default DragRow;
