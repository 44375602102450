import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import s from './TableViewResults.module.scss';
import Table from '../../../../../../components/UI/Table/Table';
import { setImageOpen, setImagesOpen, setVideoOpen } from '../../../../../../redux/slices/currentInterviewSlice';
import { useTranslation } from '../../../../../../context/LanguageProvider';
import CarouselPopup from '../../../../../../components/CarouselPopup/CarouselPopup';
import PhotoPopup from '../../../../../../components/PhotoPopup/PhotoPopup';
import VideoPopup from '../../../../../../components/VideoPopup/VideoPopup';

const TableViewResults = ({ selectedItems = [], answersCategories = [], noneIds = []}) => {
  const dispatch = useDispatch();
  const { t, key_t } = useTranslation();
  const files = useSelector(state => state.currentInterview.files);
  const videos = useSelector(state => state.currentInterview.videos);
  const [ showPhoto, setShowPhoto ] = useState(null);
  const [ showPhotos, setShowPhotos ] = useState(null);
  const [ showVideo, setShowVideo ] = useState(null);

  const getPhotoPaths = name => files.find(item => item.name === name) || null;
  const getVideoPaths = (name) => {
    if (name.split(',').length > 1) {
      return videos.filter(item => name.includes(item.name)) || null;
    }
    const vdeoArr = videos.find(item => item.name === name);
    return vdeoArr ? [ vdeoArr ] : null;
  };
  // TODO: check for translation using key_t alternative

  const isTablet = useMediaQuery({
    query: '(max-width: 900px)',
  });

  const toggleImage = (image) => {
    setShowPhoto(getPhotoPaths(image));
    dispatch(setImageOpen(true));
  };

  const toggleImages = (images) => {
    setShowPhotos(images.split(',').map(i => i.trim()).map(i => getPhotoPaths(i)));
    dispatch(setImagesOpen(true));
  };

  const toggleVideo = (video) => {
    setShowVideo(getVideoPaths(video));
    dispatch(setVideoOpen(true));
  };

  const closePhoto = () => {
    setShowPhoto(null);
    dispatch(setImageOpen(false));
  };

  const closePhotos = () => {
    setShowPhotos(null);
    dispatch(setImagesOpen(false));
  };

  const closeVideo = () => {
    setShowVideo(null);
    dispatch(setVideoOpen(false));
  };

  return (
    <div className={s.radiologyResults}>
      <Table className={s.radiologyResults_table}>
        <Table.Header>
          {answersCategories.map(header => (
            <Table.HeaderCell
              key={header}
              length={answersCategories.length}>
              {t(header)?.split('\n').map((paragraph, i) => (
                <p key={i} className='header_paragraph'>{paragraph}</p>
              ))}
            </Table.HeaderCell>))}
        </Table.Header>
        <Table.Body className={s.radiologyResults_body}>
          {selectedItems.map(row => (
            <Table.Row key={row.id}>
              {answersCategories.map((item) => {
                const isImages = item === key_t('IMAGE') && row[item] && row[item].split(',').length > 1;
                const isImage = item === key_t('IMAGE') && row[item] && row[item].split(',').length === 1;
                const isVideo = item === key_t('VIDEO') && row[item];
                let imageFile;
                let imageFiles;
                let videoFile = [];
                if (isImages) {
                  imageFiles = row[item].split(',').map(i => getPhotoPaths(i)).filter(i => i !== null);
                }
                if (isImage) {
                  imageFile = getPhotoPaths(row[item]);
                }
                if (isVideo) {
                  const isVideoArray = getVideoPaths(row[item]);
                  if (Array.isArray(isVideoArray)) {
                    videoFile = isVideoArray;
                  } else if (isVideoArray) {
                    videoFile.push(isVideoArray);
                  }
                }
                return (
                  <Table.Cell
                    className={row.Polling ? 'pollingItem' : ''}
                    key={item + row.id}
                    length={answersCategories.length}
                    title={item?.split('\n').map((paragraph, i) => (
                      <p key={i} className='header_paragraph'>{t(paragraph)}</p>
                    ))}>
                    {isImage && <div className={s.radiologyResults__image}>
                        {imageFile
                          ? <>
                            <div
                              onClick={() => toggleImage(row[item])}
                              className={s.radiologyResults__pic}>
                              <picture className={s.radiologyResults__img}>
                                <source srcSet={process.env.REACT_APP_IMAGE_URL + imageFile.webp} type='image/webp' />
                                <source srcSet={process.env.REACT_APP_IMAGE_URL + imageFile.mozJpeg} type='image/jpeg' />
                                <img className={s.radiologyResults__img} src={process.env.REACT_APP_IMAGE_URL + imageFile.mozJpeg} alt='' />
                              </picture>
                            </div>
                            {!isTablet
                    && <div
                      className={s.radiologyResults__vieWText}
                      onClick={() => toggleImage(row[item])}>{t('View')}</div>}
                          </>
                          : '-'
                      }
                      </div>}
                    {isImages && <div className={s.radiologyResults__image}>
                        {imageFiles.length
                          ? <>
                            <div
                              onClick={() => toggleImages(row[item])}
                              className={s.radiologyResults__pic}>
                              <picture className={s.radiologyResults__img}>
                                <source srcSet={process.env.REACT_APP_IMAGE_URL + imageFiles[0].webp} type='image/webp' />
                                <source srcSet={process.env.REACT_APP_IMAGE_URL + imageFiles[0].mozJpeg} type='image/jpeg' />
                                <img className={s.radiologyResults__img} src={process.env.REACT_APP_IMAGE_URL + imageFiles[0].mozJpeg} alt='' />
                              </picture>
                            </div>
                            {!isTablet
                    && <div
                      className={s.radiologyResults__vieWText}
                      onClick={() => toggleImages(row[item])}>{t('View')}</div>}
                          </>
                          : '-'
                      }
                      </div>}
                    {isVideo && <div className={s.radiologyResults__image}>
                      {videoFile.length > 0
                        ? <><section className={s.radiology__video}>
                          {videoFile.map((vdo, vIdx) => vdo && vdo.thumbnail && (<div
                            key={vIdx + vdo.thumbnail}
                            onClick={() => toggleVideo(row[item])}
                            className={s.radiologyResults__pic}>
                            <picture className={s.radiologyResults__img}>
                              <source srcSet={process.env.REACT_APP_IMAGE_URL + vdo.thumbnail} type='image/png' />
                              <img className={s.radiologyResults__img} src={process.env.REACT_APP_IMAGE_URL + vdo.thumbnail} alt='' />
                            </picture>
                          </div>))}</section>
                          {!isTablet
                  && (row[item] && <div
                    className={s.radiologyResults__vieWText}
                    onClick={() => toggleVideo(row[item])}>{t('View')}</div>)}
                        </>
                        : '-'
                    }
                    </div>}
                    {(!isImage && !isVideo && !isImages) && (noneIds.includes(row.id) ? row[item].split('***')[1] : row[item]?.split('\n').map((paragraph, i) => (
                      <p key={i} className='paragraph'>{paragraph}</p>
                    )))}
                  </Table.Cell>
                );
              })}
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      { showPhoto
        && <PhotoPopup smallPhoto img={showPhoto} onClose={closePhoto} />
      }
      { showVideo
        && <VideoPopup smallVideo video={showVideo} onClose={closeVideo} />
      }
      { showPhotos
        && <CarouselPopup
          smallPhoto
          files={showPhotos}
          onClose={closePhotos} />
      }
    </div>
  );
};

export default TableViewResults;
