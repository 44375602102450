import { GENDER_TYPES } from '../constants/names';
import manImage from '../assets/images/man.svg';
import womanImage from '../assets/images/woman.svg';
import { POPUPS_IDS } from '../components/Popups/configs/popup.configs';

export function getUuidFromUrl() {
  const splitUrl = window.location.pathname.split('/');
  return splitUrl[1] || '';
}

export function getKeyByValue(object, value) {
  return Object.keys(object).find(key => object[key] === value);
}

export function getIsDevelopment() {
  return !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
}

export function getInterviewIdFromUrl(path) {
  const url = path || window.location.pathname;
  const paths = url.split('/interviews/');
  if (!paths[1]) return null;
  const interviewId = paths[1].split('/finish')[0];
  if (!!paths[1] && !Number.isNaN(+interviewId)) return +interviewId;
  return null;
}

export function getisInterviewPage(path) {
  const paths = path ? path.split('/interviews/') : window.location.pathname.split('/interviews/');
  const interviewId = paths[1];
  if (!!paths[1] && !Number.isNaN(+interviewId)) return +interviewId;
  return null;
}

export function getPatientDefaultImage(gender) {
  if (gender === GENDER_TYPES.MR) {
    return manImage;
  }
  return womanImage;
}

export function getPatientGender(gender) {
  if (gender === GENDER_TYPES.MR) {
    return 'Male';
  }
  return 'Female';
}

export function formatTime(time) {
  const hours = Math.floor((time / (1000 * 60 * 60)) % 24);
  const minutes = Math.floor((time / 1000 / 60) % 60);
  const seconds = Math.floor((time / 1000) % 60);
  return `${hours > 0 ? hours.toString().concat(' hour ') : ''}${
    minutes > 0 ? minutes.toString().concat(minutes <= 1 ? ' min ' : ' mins ') : ''
  }${seconds} ${seconds <= 1 ? ' sec ' : ' secs '}`;
}

export function getPopupLink(str) {
  let isLinkExist = false;
  let firstText = str;
  let secondText = '';
  const link = {
    text: '',
    popupId: '',
  };
  if (typeof str === 'string') {
    const startIndex = str.indexOf('(link=');
    if (startIndex !== -1) {
      const endIndex = str.indexOf(')', startIndex);
      const linkPart = str.slice(startIndex + 1, endIndex);
      const isValid = linkPart.includes('link=') && linkPart.includes('text=') && linkPart.includes(',');
      if (endIndex !== -1 && isValid) {
        const linkParts = linkPart.split(',');
        const popupId = POPUPS_IDS[linkParts[0].split('link=')[1].trim().toUpperCase()];
        if (popupId) {
          isLinkExist = true;
          firstText = str.slice(0, startIndex);
          secondText = str.slice(endIndex + 1);
          link.text = linkParts[1].split('text=')[1];
          link.popupId = popupId;
        }
      }
    }
  }

  return [
    isLinkExist, firstText, secondText, link,
  ];
}
