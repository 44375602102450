import classNames from 'classnames';
import {
  useEffect, useRef, useState,
} from 'react';
import { PHYSICAL_ITEM_IDS } from '../../../../../../constants/physicalConstants';
import { useTranslation } from '../../../../../../context/LanguageProvider';
import s from '../../ImageInteractive.module.scss';

// const imageUrl = process.env.REACT_APP_IMAGE_URL;
const { OBSERVATION, EXAM, IMAGE } = PHYSICAL_ITEM_IDS;

const AdditionalExams = ({
  title, data, onPhysicalClick, openImage, getPhotoPaths, additionalCategories,
}) => {
  const { t } = useTranslation();
  const lastClickedRef = useRef(null);
  const listRef = useRef(null);
  const [ lastClicked, setLastClicked ] = useState(null);

  useEffect(() => {
    if (lastClicked && listRef.current) {
      lastClickedRef.current.scrollTo({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start',
      });
    }
    lastClickedRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }, [ lastClicked, listRef ]);

  const onItemClick = (id, isSelected) => {
    if (isSelected) {
      setLastClicked(id);
    }
  };

  return (
    <section className={s.physical__exams}>
      <div className={s.additional_container}>
        <div className={s.physical__examsTitle}>{t(title)}</div>
        <ul className={s.physical__examsList} ref={listRef}>
          {
          data.map((item) => {
            getPhotoPaths(item[additionalCategories[IMAGE]]);
            return (
              <li
                ref={item.id === lastClicked ? lastClickedRef : null}
                key={item.id}
                className={s.physical__examsItem}>
                <div
                  onClick={() => {
                    onPhysicalClick(item.id, item.selected, true);
                    onItemClick(item.id, item.selected, true);
                  }}
                  className={classNames(s.physical__examsName, {
                    [s.active]: item.selected && !item.Polling,
                    [s.physical_Polling_Text]: item.Polling,
                    [s.physical_Polling_Text_Active]:
                      item.Polling && item.selected,
                  })}
                >
                  {t(item[additionalCategories[EXAM]])}
                  {item.selected
                    && item[additionalCategories[OBSERVATION]]
                      ?.split('\n')
                      .map((paragraph, i) => (
                        <p key={i} className={s.physical_answer}>
                          {paragraph}
                        </p>
                      ))}
                </div>
                {/* {item.selected && <div>
                  <div className={s.physical__examsText}>
                    {item[additionalCategories[OBSERVATION]]?.split('\n').map((paragraph, i) => (
                      <p key={i} className='paragraph'>{paragraph}</p>
                    ))}
                  </div>
                  {currentImage
                && <div
                  onClick={() => openImage(item[additionalCategories[IMAGE]])}
                  className={classNames(s.physical__partImage, s.additional__image)}>
                  <picture>
                    <source srcSet={imageUrl + currentImage.webp} type='image/webp' />
                    <source srcSet={imageUrl + currentImage.mozJpeg} type='image/jpeg' />
                    <img src={imageUrl + currentImage.mozJpeg} alt='' />
                  </picture>
                  </div>}
                </div>} */}
              </li>
            );
          })
        }
        </ul>
      </div>
    </section>
  );
};

export default AdditionalExams;
