import React, {
  useMemo, useState, useRef, useEffect,
} from 'react';
import { useMediaQuery } from 'react-responsive';
import s from './InterviewTemplate.module.scss';
import InterviewSummary from './components/InterviewSummary/InterviewSummary';
import Category from '../../../../components/UI/Category/Category';
import InterviewChatBox from './components/InterviewChatBox/InterviewChatBox';
import NoCategory from '../../../../components/UI/NoCategory/NoCategory';
import { useTranslation } from '../../../../context/LanguageProvider';

const InterviewTemplate = ({ data, isSummaryPage }) => {
  const { answers, answersCategories, question } = data.data;
  const [ activeCategories, setActiveCategories ] = useState([]);
  const { t } = useTranslation();
  const allCategories = Object.entries(answers);
  const toggleCategory = (name) => {
    if (activeCategories.includes(name)) {
      return setActiveCategories(prev => prev.filter(item => item !== name));
    }
    return setActiveCategories(prev => [ ...prev, name ]);
  };

  const useCategory = question?.useCategory;
  const isMobile = useMediaQuery({
    query: '(max-width: 600px)',
  });

  const messagesRef = useRef(null);
  const scrollToBottom = () => {
    if (messagesRef?.current) {
      messagesRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start',
      });
    }
  };

  const selectedItems = useMemo(() => {
    const dataEntries = Object.entries(answers);
    const selected_items = [];
    dataEntries.forEach((item) => {
      item[1].forEach((el) => {
        if (el.selected) {
          selected_items.push({ Category: item[0], ...el });
        }
      });
    });
    return selected_items;
  }, [ answers ]);

  useEffect(() => {
    if (selectedItems.length) {
      scrollToBottom();
    }
  }, [ selectedItems ]);

  useEffect(() => {
    if (question?.useCategory && question?.expandCategory) {
      Object.entries(answers).forEach((item) => {
        if (!activeCategories.includes(item[0])) {
          toggleCategory(item[0]);
        }
      });
    }
  }, [ question?.chapterName ]);

  return (
    <div className={s.anamnesis}>
      {isSummaryPage ? (
        <InterviewSummary
          answersCategories={answersCategories}
          selectedItems={selectedItems}
        />
      ) : (
        <div className={s.anamesis_wrapper}>
          {!isMobile && <section className={s.anamesis_chatBox}>
            <div className={s.anamesis_chatBox_holder}>
              {data && data?.chatData && data?.chatData.map(item => (
                <InterviewChatBox skipChapter key={item?.id} item={item} />
              ))}
              {data && data?.chatData?.length === 0 && (<div className={s.noSelectedChat}>
                {t('No question selected')}
              </div>)}
              <div ref={messagesRef} />
            </div>
          </section>}
          <ul className={s.anamnesis__list}>
            {useCategory ? (allCategories.map(category => (
              <Category
                key={category[0]}
                title={category[0]}
                items={category[1]}
                isActive={activeCategories.includes(category[0])}
                toggleCategory={() => toggleCategory(category[0])}
                keys={answersCategories}
                isInterview
              />
            ))) : (allCategories.map(category => (
              <NoCategory
                key={category[0]}
                title={category[0]}
                items={category[1]}
                isActive={activeCategories.includes(category[0])}
                toggleCategory={() => toggleCategory(category[0])}
                keys={answersCategories}
                onClick={() => scrollToBottom}
              />
            )))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default InterviewTemplate;
